var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page-content"},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"row no-gutters page-list-header"},[_c('div',{staticClass:"col l-6 m-6 c-12"},[_c('h4',{staticClass:"page-list-title"},[_c('md-icon',[_vm._v("note_add")]),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.title))])],1)]),_c('div',{staticClass:"col l-6 m-6 c-12"},[_c('div',{staticClass:"page-list-action"},[_c('md-button',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['ctrl', 'l']),expression:"['ctrl', 'l']"}],staticClass:"md-raised md-primary",on:{"shortkey":function($event){return _vm.submit()},"click":function($event){return _vm.submit()}}},[_c('span',[_vm._v("L")]),_vm._v("ưu"),_c('md-tooltip',[_vm._v("Lưu (Ctrl + L)")])],1),_c('md-button',{directives:[{name:"shortkey",rawName:"v-shortkey",value:(['ctrl', 'i']),expression:"['ctrl', 'i']"}],staticClass:"md-raised",on:{"shortkey":function($event){return _vm.back()},"click":function($event){return _vm.back()}}},[_vm._v("Quay lạ"),_c('span',[_vm._v("i")]),_c('md-tooltip',[_vm._v("Quay lại (Ctrl + Q)")])],1)],1)])]),_c('div',{staticClass:"page-list-body"},[_c('div',{staticClass:"form-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col l-6 m-6 c-12"},[_c('div',{staticClass:"form-control"},[_c('md-autocomplete',{class:{'md-invalid': _vm.submitted && _vm.$v.entity.categoryId.$error },attrs:{"md-options":_vm.categories},on:{"md-selected":_vm.getCategorySelected,"md-changed":_vm.getCategories,"md-opened":_vm.getCategories},scopedSlots:_vm._u([{key:"md-autocomplete-item",fn:function(ref){
var item = ref.item;
var term = ref.term;
return [_c('md-highlight-text',{attrs:{"md-term":term}},[_vm._v(_vm._s(item.categoryName))])]}},{key:"md-autocomplete-empty",fn:function(ref){
var term = ref.term;
return [_vm._v(" \""+_vm._s(term)+"\" Không tìm thấy! ")]}}]),model:{value:(_vm.categoryName),callback:function ($$v) {_vm.categoryName=$$v},expression:"categoryName"}},[_c('label',[_vm._v("Nhóm vật tư")]),(_vm.submitted && !_vm.$v.entity.categoryId.required)?_c('span',{staticClass:"md-error"},[_vm._v("Vui lòng chọn nhóm vật tư")]):_vm._e()]),_c('md-button',{staticClass:"md-icon-button",on:{"click":function($event){return _vm.openCategory()}}},[_c('md-icon',[_vm._v("manage_search")])],1)],1),_c('md-field',{class:{'md-invalid': _vm.submitted && _vm.$v.entity.productCode.$error }},[_c('label',{attrs:{"for":"code"}},[_vm._v("Mã vật tư")]),_c('md-input',{attrs:{"name":"code"},model:{value:(_vm.entity.productCode),callback:function ($$v) {_vm.$set(_vm.entity, "productCode", $$v)},expression:"entity.productCode"}}),(_vm.submitted && !_vm.$v.entity.productCode.required)?_c('span',{staticClass:"md-error"},[_vm._v("Vui lòng nhập mã vật tư")]):_vm._e()],1),_c('md-field',{class:{'md-invalid': _vm.submitted && _vm.$v.entity.productName.$error }},[_c('label',{attrs:{"for":"productName"}},[_vm._v("Tên vật tư")]),_c('md-input',{attrs:{"name":"productName"},model:{value:(_vm.entity.productName),callback:function ($$v) {_vm.$set(_vm.entity, "productName", $$v)},expression:"entity.productName"}}),(_vm.submitted && !_vm.$v.entity.productName.required)?_c('span',{staticClass:"md-error"},[_vm._v("Vui lòng nhập tên vật tư")]):_vm._e()],1),_c('div',{staticClass:"form-control"},[_c('md-autocomplete',{class:{'md-invalid': _vm.submitted && _vm.$v.entity.unitId.$error },attrs:{"md-options":_vm.units},on:{"md-selected":_vm.getUnitSelected,"md-changed":_vm.getUnits,"md-opened":_vm.getUnits},scopedSlots:_vm._u([{key:"md-autocomplete-item",fn:function(ref){
var item = ref.item;
var term = ref.term;
return [_c('md-highlight-text',{attrs:{"md-term":term}},[_vm._v(_vm._s(item.unitName))])]}},{key:"md-autocomplete-empty",fn:function(ref){
var term = ref.term;
return [_vm._v(" \""+_vm._s(term)+"\" Không tìm thấy! ")]}}]),model:{value:(_vm.unitName),callback:function ($$v) {_vm.unitName=$$v},expression:"unitName"}},[_c('label',[_vm._v("Đơn vị tính")]),(_vm.submitted && !_vm.$v.entity.unitId.isSelected)?_c('span',{staticClass:"md-error"},[_vm._v("Vui lòng chọn đơn vị tính")]):_vm._e()]),_c('md-button',{staticClass:"md-icon-button",on:{"click":function($event){return _vm.openUnit()}}},[_c('md-icon',[_vm._v("manage_search")])],1)],1),_c('div',{staticClass:"form-control"},[_c('md-radio',{attrs:{"value":true},model:{value:(_vm.entity.isActive),callback:function ($$v) {_vm.$set(_vm.entity, "isActive", $$v)},expression:"entity.isActive"}},[_vm._v("Hoạt động")]),_c('md-radio',{attrs:{"value":false},model:{value:(_vm.entity.isActive),callback:function ($$v) {_vm.$set(_vm.entity, "isActive", $$v)},expression:"entity.isActive"}},[_vm._v("Khóa")])],1)],1),_c('div',{staticClass:"col l-6 m-6 c-12"},[(_vm.entity.typeCode == '1')?_c('md-field',[_c('label',{attrs:{"for":"tireStatus"}},[_vm._v("Tình trạng(%)")]),_c('md-input',{attrs:{"name":"tireStatus"},model:{value:(_vm.entity.tireStatus),callback:function ($$v) {_vm.$set(_vm.entity, "tireStatus", $$v)},expression:"entity.tireStatus"}}),_c('span',{staticClass:"md-suffix"},[_vm._v("%")])],1):_vm._e(),(_vm.entity.typeCode == '1')?_c('md-field',[_c('label',{attrs:{"for":"kmReplace"}},[_vm._v("Km thay thế(km)")]),_c('md-input',{attrs:{"name":"kmReplace"},model:{value:(_vm.entity.kmReplace),callback:function ($$v) {_vm.$set(_vm.entity, "kmReplace", $$v)},expression:"entity.kmReplace"}}),_c('span',{staticClass:"md-suffix"},[_vm._v("KM")])],1):_vm._e()],1)])])])]),_c('unitList',{ref:"unitList",on:{"close":_vm.closeUnit}}),_c('categoryList',{ref:"categoryList",attrs:{"type":3,"title":"nhóm vật tư"},on:{"close":_vm.closeCategory}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }